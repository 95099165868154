// appModule.js
const state = {
    Theme: "light",
    LayoutType: "full-sidebar",
    Sidebar_drawer: false,
    Customizer_drawer: false,
    SidebarColor: "white",
    navbarColor: "primary",
    logoColor: "white",
    setHorizontalLayout: false,
    Direction: "ltr",
  };
  
  const mutations = {
    SET_THEME(state, payload) {
      state.Theme = payload;
    },
    SET_LAYOUT_TYPE(state, payload) {
      state.LayoutType = payload;
    },
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LOGO_COLOR(state, payload) {
      state.logoColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
    SET_DIRECTION(state, payload) {
      state.Direction = payload;
    },
  };
  
  const actions = {
    setLayoutType({ commit }, width) {
      commit("SET_LAYOUT_TYPE", width);
    },
    addCalendarEvent({ commit }, event) {
        commit("ADD_CALENDAR_EVENT", event);
    },
  };
  
  const getters = {};
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
  