import axios from "axios";
const url = "general/Api/States/"

export default {
    namespaced: true,
    state() {
        return {
            states: [],
            totalStates: 0
        }
    },
    getters: {
        getStates(state) { return state.states },
        getTotalStates(state) { return state.totalStates }
    },
    mutations: {
        SET_STATES(state, states) {
            state.states = states;
        },
        SET_TOTAL_STATES(state, totalStates) {
            state.totalStates = totalStates;
        }
    },
    actions: {
        async get_allStates({ commit }, payload) {
            try {
                const response = await axios.get(`${url}GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}`);
                commit('SET_STATES', response.data.dataResult);
                commit('SET_TOTAL_STATES', response.data.dataRecords);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting states', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async get_states(_,id) {
            try {
                const response = await axios.get(`${url}GetById/${id}`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error get state by ID', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async create_state({rootGetters}, payload) {
            try {
                const response = await axios.post(`${url}Add`, {
                    //Name, CountryId, CreatedBy, IsActive
                    Name: payload.description,
                    CountryId: payload.countryId,
                    CreatedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating state', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async update_state({rootGetters}, state) {
            try {
                const response = await axios.put(`${url}Update`, {
                    // Id, Name, CountryId, ModifiedBy, IsActive
                    Id: state.id,
                    Name: state.description,
                    CountryId: state.countryId,
                    ModifiedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                
                return response.data;     
            } catch (error) {
                this._vm.$bvToast.toast('Error update state', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        }
    }
}