// index.js
import Vue from "vue";
import Vuex from "vuex";
import theme from "./theme";
import users from "./user";
import roles from "./roles";
import authorizations from "./authorizations";
import auth from "./auth";
import loanstypes from "./loans/types";
import rules from "./loans/rules";
import customerstypes from "./general/customersTypes";
import banks from "./general/banks.js";
import cities from "./general/cities.js";
import states from "./general/states.js";
import customers from "./customers";
import mambu from "./mambu";
import countries from "./general/countries.js";
import documentsTypes from "./customers/documentsTypes.js";
import documents from "./customers/documents.js";
import comments from "./customers/comments.js";
import employments from "./customers/employments.js";
import additionalDetails from "./customers/additionalDetails.js";
import personIdentificationTypes from "./customers/PersonIdentificationTypes.js";
import scans from "./customers/scans.js";
import screening from "./customers/screening.js";
import applications from "./loans/applications.js";
import productRequest from "./customers/productRequest.js";
import signature from "./loans/signature.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    theme: theme,
    users: users,
    roles: roles,
    authorizations: authorizations,
    auth: auth,
    loanstypes: loanstypes,
    rules: rules,
    customerstypes: customerstypes,
    banks: banks,
    cities: cities,
    countries: countries,
    states: states,
    customers: customers,
    documentsTypes: documentsTypes,
    documents: documents,
    comments: comments,
    employments: employments,
    additionalDetails: additionalDetails,
    personIdentificationTypes: personIdentificationTypes,
    scans: scans,
    screening: screening,
    applications: applications,
    productRequest: productRequest,
    mambu: mambu,
    signature
  },
});

export default store;