import axios from "axios";

const url = "auth/Api/Authorizations/";

export default {
	namespaced: true,
	state() {
		return {
			authorizations: [],
		};
	},
	getters: {
		getAuthorizations(state) {
			return state.authorizations;
		},
	},
	mutations: {
		SET_AUTHORIZATIONS(state, authorizations) {
			state.authorizations = authorizations;
		},
	},
	actions: {
		async get_allAuthorizations({ commit }) {
			try {
				const response = await axios.get(`${url}GetAll?Page=1&RecordsPerPage=50`);
				commit("SET_AUTHORIZATIONS", response.data.dataResult);
			} catch (error) {
				console.log(error);
			}
		},
		async get_authorization(id) {
			try {
				const response = await axios.get(`${url}GetById/${id}`);
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},
		async add_authorization(authorization) {
			try {
				const response = await axios.post(`${url}Add`, {
					Description: authorization.description,
					CreatedBy: authorization.createdBy,
					RouteValue: authorization.routeValue,
					IsActive: authorization.isActive,
				});
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},
		async update_authorization(authorization) {
			try {
				const response = await axios.put(`${url}Update`, {
					Id: authorization.id,
					Description: authorization.description,
					ModifiedBy: authorization.modifiedBy,
					RouteValue: authorization.routeValue,
					IsActive: authorization.isActive,
				});
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},
		async update_authorizationStatus(_, authorization) {
			try {
				const response = await axios.put(`${url}UpdateStatus`, {
					Id: authorization.id,
					ModifiedBy: authorization.modifiedBy,
					IsActive: authorization.isActive,
				});
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},
	},
};
