import axios from "axios";
const url = "auth/Api/Users/";

export default {
	// namespace the module
	namespaced: true,
	state() {
		return {
			users: [],
			totalUsers: 0,
		};
	},
	getters: {
		getUsers(state) {
			return state.users;
		},
		getTotalUsers(state) {
			return state.totalUsers;
		},
	},
	mutations: {
		SET_USERS(state, users) {
			state.users = users;
		},
		SET_TOTAL_USERS(state, totalUsers) {
			state.totalUsers = totalUsers;
		},
	},
	actions: {
		async get_allUsers({ commit }, { skip, take }) {
			try {
				const response = await axios.get(`${url}GetAll?Page=${skip}&RecordsPerPage=${take}`);
				commit("SET_USERS", response.data.dataResult);
				commit("SET_TOTAL_USERS", response.data.dataRecords);
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},

		async get_loanOfficers() {
			try {
				const response = await axios.get(`${url}GetLoanOfficers`);
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},
		async get_user(_, id) {
			try {
				const response = await axios.get(`${url}GetById/${id}`);
				return response.data.dataResult;
			} catch (error) {
				console.log(error);
			}
		},
		async add_user({ rootGetters }, user) {
			try {
				const response = await axios.post(`${url}Add`, {
					FirstName: user.firstName,
					LastName: user.lastName,
					UserName: user.userName,
					Email: user.email,
					ProfilePicture: user.profilePicture,
					PhoneNumber: user.phoneNumber,
					BirthDate: user.birthDate,
					Password: user.password,
					CreatedBy: rootGetters["auth/getUser"].Id,
					IsActive: user.isActive,
					role_Id: user.roleId,
				});
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},
		async update_user({ rootGetters }, user) {
			try {
				const response = await axios.put(`${url}Update`, {
					// Id, FirstName, LastName, UserName, Email, ProfilePicture, PhoneNumber, BirthDate, Password, ModifiedBy, IsActive
					Id: user.id,
					FirstName: user.firstName,
					LastName: user.lastName,
					UserName: user.userName,
					Email: user.email,
					ProfilePicture: user.profilePicture,
					PhoneNumber: user.phoneNumber,
					BirthDate: user.birthDate,
					Password: user.password,
					ModifiedBy: rootGetters["auth/getUser"].Id,
					IsActive: user.isActive,
					role_Id: user.roleId,
				});
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},
		async update_userStatus({ rootGetters }, user) {
			try {
				const response = await axios.put(`${url}UpdateStatus`, {
					// Id, ModifiedBy, IsActive
					Id: user.id,
					ModifiedBy: rootGetters["auth/getUser"].Id,
					IsActive: user.isActive,
				});
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},
		async disable_password({ rootGetters }, user) {
			try {
				const response = await axios.put(`${url}DisablePassword`, {
					// Id, ModifiedBy
					Id: user.id,
					ModifiedBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},
		async update_password({ rootGetters }, user) {
			try {
				const response = await axios.put(`${url}UpdatePassword`, {
					// Id, Password, ModifiedBy
					Id: user.id,
					Password: user.password,
					ModifiedBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},
		async add_userRole({ rootGetters }, userRole) {
			try {
				const response = await axios.post(`${url}AddRole`, {
					//User_Id, Role_Id, CreatedBy
					user_Id: userRole.userId,
					// role_Id: userRole.roleId,
					Role_Ids: userRole.roleIds,
					createdBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},
		async remove_userRole({ rootGetters }, userRole) {
			try {
				const response = await axios.put(`${url}RemoveRoles`, {
					//User_Id, ModifiedBy
					User_Id: userRole.userId,
					ModifiedBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},
	},
};
