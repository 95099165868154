import axios from "axios";


export default {
    namespaced: true,
    state() {
        return {
            personIdentificationTypes: [],
        }
    },
    getters: {
        getPersonIdentificationTypes(state) { return state.personIdentificationTypes }
    },
    mutations: {
        SET_PERSON_IDENTIFICATION_TYPES(state, personIdentificationTypes) {
            state.personIdentificationTypes = personIdentificationTypes;
        },
    },
    actions: {
        async get_person_identification_types({commit}) {
            try {
                const response = await axios.get(`customers/Api/PersonIdentificationTypes/GetAll`);
                commit('SET_PERSON_IDENTIFICATION_TYPES', response.data.dataResult);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting person identification types', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
    }
}