import axios from "axios";
const url = "general/Api/Countries/"

export default {
    namespaced: true,
    state() {
        return {
            countries: [],
            totalCountries: 0
        }
    },
    getters: {
        getCountries(state) { return state.countries },
        getTotalCountries(state) { return state.totalCountries }
    },
    mutations: {
        SET_COUNTRIES(state, countries) {
            state.countries = countries;
        },
        SET_TOTAL_COUNTRIES(state, totalCountries) {
            state.totalCountries = totalCountries;
        }
    },
    actions: {
        async get_allCountries({ commit }, payload) {
            try {
                const response = await axios.get(`${url}GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}`);
                commit('SET_COUNTRIES', response.data.dataResult);
                commit('SET_TOTAL_COUNTRIES', response.data.dataRecords);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting countries', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async get_countries(_,id) {
            try {
                const response = await axios.get(`${url}GetById/${id}`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting countries by id', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async create_countries({rootGetters}, payload) {
            try {
                const response = await axios.post(`${url}Add`, {
                    //Name, Code, ISO_Code, CreatedBy, IsActive
                    Name: payload.name,
                    Code: payload.code,
                    ISO_Code: payload.isO_Code,
                    CreatedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating country', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async update_country({rootGetters}, country) {
            try {
                const response = await axios.put(`${url}Update`, {
                    // Id, Name, Code, ISO_Code, ModifiedBy, IsActive
                    Id: country.id,
                    Name: country.name,
                    Code: country.code,
                    ISO_Code: country.isO_Code,
                    ModifiedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error update country', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        }
         
    }
}