import axios from "axios";


export default {
    namespaced: true,
    state() {
        return {
            employments: []
        }
    },
    getters: {
        getEmployments(state) { return state.employments }
    },
    mutations: {
        SET_EMPLOYMENTS(state, employments) {
            state.employments = employments;
        }
    },
    actions: {
        async get_employments({commit}, payload) {
            try {
                const response = await axios.get(`customers/Api/Employments/GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}&customerId=${payload.customerId}`);
                commit('SET_EMPLOYMENTS', response.data.dataResult);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting employments', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async get_employmentById(_, payload) {
            try {
                const response = await axios.get(`customers/Api/Employments/GetById/${payload}`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting employment', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async add_employment({rootGetters}, payload) {

            payload.isActive = true
            payload.isVerified = true
            payload.clasificationId = 1
            payload.createdBy = rootGetters['auth/getUser'].Id
            try {
                const response = await axios.post(`customers/Api/Employments/Add`, payload);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error adding employment', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async update_employment({rootGetters}, payload) {
            try {
                let data = payload
                data.modifiedBy = rootGetters['auth/getUser'].Id
                const response = await axios.put(`customers/Api/Employments/Update`, data);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating employment', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async update_employmentStatus({rootGetters}, payload) {
            try {
                let data = payload
                data.modifiedBy = rootGetters['auth/getUser'].Id
                const response = await axios.put(`customers/Api/Employments/UpdateStatus`, data);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating employment status', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async update_employmentVerification({rootGetters}, payload) {
            try {
                let data = payload
                data.modifiedBy = rootGetters['auth/getUser'].Id
                const response = await axios.put(`customers/Api/Employments/UpdateIsVerified`, data);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating employment verification', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        }
        

    }
}