import axios from "axios";

export default {
	namespaced: true,
	state() {
		return {
			screening: [],
			screeningByBank: [],
			totalScreeningByBank: 0,
			totalScreening: 0,
		};
	},
	getters: {
		getScreening(state) {
			return state.screening;
		},
		getTotalScreening(state) {
			return state.totalScreening;
		},
		getScreeningByBank(state) {
			return state.screeningByBank;
		},
		getTotalScreeningByBank(state) {
			return state.totalScreeningByBank;
		},
	},
	mutations: {
		SET_SCREENING(state, screening) {
			state.screening = screening;
		},
		SET_TOTAL_SCREENING(state, data) {
			state.totalScreening = data;
		},
		SET_SCREENING_BY_BANK(state, screening) {
			state.screeningByBank = screening;
		},
		SET_TOTAL_SCREENING_BY_BANK(state, data) {
			state.totalScreeningByBank = data;
		},
	},
	actions: {
		async get_screeningByCustomerId({ commit }, payload) {
			try {
				const response = await axios.get(`customers/Api/ScreeningVerification/GetByCustomerId/${payload}`);
				commit("SET_SCREENING", response.data.dataResult);
				commit("SET_TOTAL_SCREENING", response.data.dataRecords);
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting screening verifications", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async get_screeningByLoanAppId({ commit }, payload) {
			try {
				const response = await axios.get(`customers/Api/ScreeningVerification/GetByLoanApplicationId/${payload}`);
				commit("SET_SCREENING", response.data.dataResult);
				commit("SET_TOTAL_SCREENING", response.data.dataRecords);
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting screening verifications", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async get_screeningByBankId({ commit }, payload) {
			try {
				const response = await axios.get(`customers/Api/ScreeningVerification/GetByBankId/${payload}`);
				commit("SET_SCREENING_BY_BANK", response.data.dataResult);
				commit("SET_TOTAL_SCREENING_BY_BANK", response.data.dataRecords);
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting screening verifications", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async add_screeningVerification({ rootGetters }, payload) {
			try {
				const response = await axios.post(`customers/Api/ScreeningVerification/Add`, {
					customerId: payload,
					createdBy: rootGetters["auth/getUser"].Id,
					guid: "",
				});
				this._vm.$bvToast.toast("Screening verification added successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error adding screening verifications", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async update_screeningStatus({ rootGetters }, payload) {
			try {
				const response = await axios.put(`customers/Api/ScreeningVerification/UpdateStatus`, {
					id: payload.id,
					isActive: payload.isActive,
					modifiedBy: rootGetters["auth/getUser"].Id,
					guid: "",
				});
				this._vm.$bvToast.toast("Screening verification updated successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating screening verifications", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async update_fromVerificationForm({ rootGetters }, payload) {
			try {
				let data = payload;
				data.modifiedBy = rootGetters["auth/getUser"].Id;
				const response = await axios.put(`customers/Api/ScreeningVerification/UpdateFromVerificationForm`, payload);
				this._vm.$bvToast.toast("Screening verification updated successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating screening verifications", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async send_Emails(_, id) {
			try {
				const response = await axios.put(`customers/Api/ScreeningVerification/SendEmails`, {
					CustomerId: id,
				});
				this._vm.$bvToast.toast("Emails sent successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error sending emails", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async get_bankInformation(_, payload) {
			try {
				const response = await axios.get(`customers/Api/ScreeningVerification/GetBankInformation?ID=${payload.id}&GUID=${payload.guid}`);
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting bank information", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
	},
};
