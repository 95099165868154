<template>
  <div id="main-wrapper">
    <router-view />
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
    
  },
  created() {
    this.$store.dispatch('auth/setToken')
  }
}
</script>

<style>

</style>
