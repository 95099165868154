import axios from "axios";
const url = "general/Api/CustomerTypes/"

export default {
    namespaced: true,
    state() {
        return {
            customersTypes: [],
            totalCustomersTypes: 0
        }
    },
    getters: {
        getcustomersTypes(state) { return state.customersTypes },
        getTotalCustomersTypes(state) { return state.totalCustomersTypes }
    },
    mutations: {
        SET_CUSTOMERSTYPES(state, customersTypes) {
            state.customersTypes = customersTypes;
        },
        SET_TOTAL_CUSTOMERSTYPES(state, totalCustomersTypes) {
            state.totalCustomersTypes = totalCustomersTypes;
        }
    },
    actions: {
        async get_allCustomersTypes({ commit }, payload) {
            try {
                const response = await axios.get(`${url}GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}`);
                commit('SET_CUSTOMERSTYPES', response.data.dataResult);
                commit('SET_TOTAL_CUSTOMERSTYPES', response.data.dataRecords);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting customerstype', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async get_customersTypes(_,id) {
            try {
                const response = await axios.get(`${url}GetById/${id}`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error get customer type by ID', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async create_customersTypes({rootGetters}, payload) {
            try {
                const response = await axios.post(`${url}Add`, {
                    //Name, CreatedBy, IsActive
                    Name: payload.name,
                    CreatedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating customerstype', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async update_customersTypes({rootGetters}, customerType) {
            try {
                const response = await axios.put(`${url}Update`, {
                    // Id, Name, ModifiedBy, IsActive
                    Id: customerType.id,
                    Name: customerType.name,
                    ModifiedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error update customerType', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        }
    }
}