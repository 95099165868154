import axios from "axios";

const url = 'customers/Api/DocumentTypes/';

export default {
    namespaced: true,
    state() {
        return {
            documentsTypes: [],
            totalDocumentsTypes: 0
        }
    },
    getters: {
        getDocumentsTypes(state) { return state.documentsTypes },
        getTotalTypes(state) { return state.totalDocumentsTypes }
    },
    mutations: {
        SET_DOCUMENTSTYPES(state, documentsTypes) {
            state.documentsTypes = documentsTypes;
        },
        SET_TOTAL_DOCUMENTSTYPES(state, totalDocumentsTypes) {
            state.totalDocumentsTypes = totalDocumentsTypes;
        }
    },
    actions: {
        async get_allDocumentsTypes({ commit }, payload) {
            try {
                const response = await axios.get(`${url}GetAll?Page=${payload?.skip ?? 1}&RecordsPerPage=${payload?.take?? 999}`);
                commit('SET_DOCUMENTSTYPES', response.data.dataResult);
                commit('SET_TOTAL_DOCUMENTSTYPES', response.data.dataRecords);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting documentsTypes', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async get_documentsTypes(_,id) {
            try {
                const response = await axios.get(`${url}GetById/${id}`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error get documentstypes by ID', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async create_documentsTypes({rootGetters}, payload) {
            try {
                const response = await axios.post(`${url}Add`, {
                    // Description, CreatedBy, IsActive
                    Description: payload.description,
                    CreatedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating documentsTypes', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async update_documentsTypes({rootGetters}, documentsTypes) {
            try {
                const response = await axios.put(`${url}Update`, {
                    // Id, Description, ModifiedBy, IsActive
                    Id: documentsTypes.id,
                    Description: documentsTypes.description,
                    ModifiedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error update documentsTypes', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        }
    }
}