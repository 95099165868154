import axios from "axios";

export default {
	namespaced: true,
	state() {
		return {
			process: {},
		};
	},
	getters: {
		getProcess(state) {
			return state.process;
		},
	},
	mutations: {
		SET_PROCESS(state, process) {
			state.process = process;
		},
	},
	actions: {
		async get_processResquest({ commit }, payload) {
			try {
				const response = await axios.get(`customers/Api/ProductRequestProcess/GetByLoanApplicationId/${payload}`);
				commit("SET_PROCESS", response.data.dataResult);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting customer process", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_statusDocuments({ rootGetters }, payload) {
			try {
				const response = await axios.put(`customers/Api/ProductRequestProcess/UpdateStatus`, {
					LoanApplicationId: payload.id,
					Status: payload.status,
					modifiedBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating status", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async update_statusAdditionalDetails({ rootGetters }, payload) {
			try {
				const response = await axios.put(`customers/Api/ProductRequestProcess/UpdateStatusAdditionalDetails`, {
					LoanApplicationId: payload.id,
					Status: payload.status,
					modifiedBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating status", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_statusMembercheck({ rootGetters }, payload) {
			try {
				const response = await axios.put(`customers/Api/ProductRequestProcess/UpdateStatusMembercheck`, {
					LoanApplicationId: payload.id,
					Status: payload.status,
					modifiedBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating status", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_statusScreening({ rootGetters }, payload) {
			try {
				const response = await axios.put(`customers/Api/ProductRequestProcess/UpdateStatusScreening`, {
					LoanApplicationId: payload.id,
					Status: payload.status,
					modifiedBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating status", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_statusJobVerification({ rootGetters }, payload) {
			try {
				const response = await axios.put(`customers/Api/ProductRequestProcess/UpdateStatusJobVerification`, {
					LoanApplicationId: payload.id,
					Status: payload.status,
					modifiedBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating status", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_statusVerifyAllInformation({ rootGetters }, payload) {
			try {
				const response = await axios.put(`customers/Api/ProductRequestProcess/UpdateStatusVerifyAllInformation`, {
					LoanApplicationId: payload.id,
					Status: payload.status,
					modifiedBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating status", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_statusApprovalLvl1({ rootGetters }, payload) {
			try {
				const response = await axios.put(`customers/Api/ProductRequestProcess/UpdateStatusApprovalLvl1`, {
					LoanApplicationId: payload.id,
					Status: payload.status,
					modifiedBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating status", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_statusApprovalLvl1_2({ rootGetters }, payload) {
			try {
				const response = await axios.put(`customers/Api/ProductRequestProcess/UpdateStatusApprovalLvl1_2`, {
					LoanApplicationId: payload.id,
					Status: payload.status,
					modifiedBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating status", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_statusApprovalLvl2({ rootGetters }, payload) {
			try {
				const response = await axios.put(`customers/Api/ProductRequestProcess/UpdateStatusApprovalLvl2`, {
					LoanApplicationId: payload.id,
					Status: payload.status,
					modifiedBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating status", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_statusSigned({ rootGetters }, payload) {
			try {
				const response = await axios.put(`customers/Api/ProductRequestProcess/UpdateStatusSigned`, {
					LoanApplicationId: payload.id,
					Status: payload.status,
					modifiedBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating status", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async update_statusTransferredFunds({ rootGetters }, payload) {
			try {
				const response = await axios.put(`customers/Api/ProductRequestProcess/UpdateStatusTransferredFunds`, {
					LoanApplicationId: payload.id,
					Status: payload.status,
					modifiedBy: rootGetters["auth/getUser"].Id,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating status", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
	},
};
