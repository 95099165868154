import axios from "axios";

export default {
	namespaced: true,
	state() {
		return {
			comments: [],
			totalComments: 0,
		};
	},
	getters: {
		getComments(state) {
			return state.comments;
		},
		getTotalComments(state) {
			return state.totalComments;
		},
	},
	mutations: {
		SET_COMMENTS(state, comments) {
			state.comments = comments;
		},
		SET_TOTAL_COMMENTS(state, data) {
			state.totalComments = data;
		},
	},
	actions: {
		async get_comments({ commit }, payload) {
			try {
				const response = await axios.get(
					`customers/Api/Comments/GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}&customerId=${payload.customerId}`
				);
				commit("SET_COMMENTS", response.data.dataResult);
				commit("SET_TOTAL_COMMENTS", response.data.dataRecords);
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting comments", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async get_commentsByLoanAppId({ commit }, payload) {
			try {
				const response = await axios.get(`customers/Api/Comments/GetByLoanApplicationId/${payload}`);
				commit("SET_COMMENTS", response.data.dataResult);
				commit("SET_TOTAL_COMMENTS", response.data.dataRecords);
				return response.data.dataResult;
			} catch (error) {
				// this._vm.$bvToast.toast("Error getting comments", {
				// 	title: "Error",
				// 	variant: "danger",
				// 	solid: true,
				// });
			}
		},
		async add_comment({ rootGetters }, payload) {
			try {
				const response = await axios.post(`customers/Api/Comments/Add`, {
					customerId: payload.customerId,
					loanApplicationId: payload.loanApplicationId,
					description: payload.comment,
					createdBy: rootGetters["auth/getUser"].Id,
					isActive: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error adding comment", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async update_comment({ rootGetters }, payload) {
			try {
				const response = await axios.put(`customers/Api/Comments/Update`, {
					id: payload.id,
					customerId: payload.customerId,
					description: payload.comment,
					ModifiedBy: rootGetters["auth/getUser"].Id,
					isActive: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating comment", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async update_status({ rootGetters }, payload) {
			try {
				const response = await axios.put(`customers/Api/Comments/UpdateStatus`, {
					id: payload.id,
					isActive: payload.isActive,
					ModifiedBy: rootGetters["auth/getUser"].Id,
					Description: payload.description,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating comment status", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
	},
};
