import axios from "axios";
const url = "general/Api/Banks/"
export default {
    namespaced: true,
    state() {
        return {
            banks: [],
            banksDDL: [],
            totalBanks: 0
        }
    },
    getters: {
        getBanks(state) { return state.banks },
        getBanksDDL(state) { return state.banksDDL },
        getTotalBanks(state) { return state.totalBanks }
    },
    mutations: {
        SET_BANKS(state, banks) {
            state.banks = banks;
        },
        SET_TOTAL_BANKS(state, totalBanks) {
            state.totalBanks = totalBanks;
        },
        SET_BANKS_DDL(state, banksDDL) {
            state.banksDDL = banksDDL;
        }
    },
    actions: {
        async get_allBanks({ commit }, payload) {
            try {
                const response = await axios.get(`${url}GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}`);
                commit('SET_BANKS', response.data.dataResult);
                commit('SET_TOTAL_BANKS', response.data.dataRecords);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting banks', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async get_bank(_,id) {
            try {
                const response = await axios.get(`${url}GetById/${id}`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error get bank by ID', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async get_allBanksDDL({ commit }) {
            try {
                const response = await axios.get(`${url}GetAllDDL`);
                commit('SET_BANKS_DDL', response.data.dataResult);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting banks', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async create_bank({rootGetters}, payload) {
            try {
                const response = await axios.post(`${url}Add`, {
                    //Name, CreatedBy, IsActive
                    Name: payload.name,
                    CreatedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating bank', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async update_bank({rootGetters}, bank) {
            try {
                const response = await axios.put(`${url}Update`, {
                    // Id, Name, ModifiedBy, IsActive
                    Id: bank.id,
                    Name: bank.name,
                    ModifiedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error update bank', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        }
    }
}