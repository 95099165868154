import axios from "axios";
const url = "loans/Api/LoanApplications/";
const urlCompany = "loans/Api/CompanyLoansApplications/";

export default {
	namespaced: true,
	state() {
		return {
			applications: [],
			totalApplications: 0,
			loanApp: null
		};
	},
	getters: {
		getApplications(state) {
			return state.applications;
		},
		getTotalApplications(state) {
			return state.totalApplications;
		},
		getLoan(state) {
			return state.loanApp;
		},
	},
	mutations: {
		SET_APPLICATIONS(state, applications) {
			state.applications = applications;
		},
		SET_TOTAL_APPLICATIONS(state, totalApplications) {
			state.totalApplications = totalApplications;
		},
		SET_LOAN_APPLICATION(state, loan) {
			state.loanApp = loan
		}
	},
	actions: {
		async get_dashboardInformation() {
			try {
				const response = await axios.get(`${url}GetDashboardInformation`);
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting applications", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_allApplications({ commit, rootGetters }, payload) {
			try {
				let user = rootGetters["auth/getUser"];
				let path = `${url}GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}&SortDirection=${payload.sortDirection}&SortProperty=${payload.sortProperty}`;
				if (payload.agentId) {
					path += `&AgentId=${user.Id}`;
				}

				if (payload.slug) path = path + `&Slug=${payload.slug}`;

				path = path + `&SearchParam=${payload.query}`;
				const response = await axios.get(path);
				commit("SET_APPLICATIONS", response.data.dataResult);
				commit("SET_TOTAL_APPLICATIONS", response.data.dataRecords);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting applications", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async get_loanById({commit}, id) {
			try {
				const response = await axios.get(`${url}GetById/${id}`);
				commit('SET_LOAN_APPLICATION', response.data.dataResult)
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting loan", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_loanOfficer(_, payload) {
			try {
				let path = `${url}UpdateLoanOfficer?LoanApplicationId=${payload.loanId}&AgentId=${payload.agentId}`;
				const response = await axios.post(path);
				this._vm.$bvToast.toast("Officer updated successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating loan officer", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_allBuilders({ rootGetters }, payload) {
			try {
				const response = await axios.get(
					`${urlCompany}/GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}&CompanyId=${rootGetters["auth/getUser"].CompanyId}`
				);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting applications", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_companyInformation({ rootGetters }) {
			try {
				const response = await axios.get(`${urlCompany}GetCompanyInformation/${rootGetters["auth/getUser"].CompanyId}`);
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting company information", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_monthsByLoanType({ rootGetters }, payload) {
			try {
				const response = await axios.get(
					`${urlCompany}GetMonthsByLoanType?Amount=${payload.amount}&LoanTypeId=1&CompanyId=${rootGetters["auth/getUser"].CompanyId}`
				);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting months", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_monthlyPayment({ rootGetters }, payload) {
			try {
				const response = await axios.get(
					`${urlCompany}GetMonthtlyPayment?Amount=${payload.amount}&Months=${payload.months}&LoanTypeId=1&CompanyId=${rootGetters["auth/getUser"].CompanyId}`
				);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting monthly payment", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async get_companyLoanRange({ rootGetters }) {
			try {
				const response = await axios.get(`${urlCompany}GetLoanRange?LoanTypeId=1&CompanyId=${rootGetters["auth/getUser"].CompanyId}`);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting months", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async get_loanRange(_, id) {
			try {
				const response = await axios.get(`${url}GetLoanRange?LoanTypeId=${id}`);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting months", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async add_builders({ rootGetters }, applicationData) {
			try {
				const formData = new FormData();
				formData.append("LoanTypeId", 1);
				formData.append("Amount", applicationData.amount);
				formData.append("MonthPeriod", applicationData.periodInMonths);
				formData.append("MonthPayment", applicationData.monthlyPayment);
				formData.append("FirstName", applicationData.firstName);
				formData.append("LastName", applicationData.lastName);
				formData.append("Email", applicationData.email);
				formData.append("Telephone", applicationData.phone);
				formData.append("Address", applicationData.address);
				formData.append("Employeer", applicationData.employeerName);
				formData.append("DocumentId", applicationData.id);
				formData.append("Payslip", applicationData.payslip1);
				formData.append("Others", applicationData.other);
				formData.append("Quote", applicationData.quote);
				formData.append("CompanyId", rootGetters["auth/getUser"].CompanyId);

				const response = await axios.post(`${urlCompany}Add`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});
				this._vm.$bvToast.toast("Loan request has been sent to Total.", {
					title: "Upload complete.",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error saving data", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_typeSignature({ rootGetters }, payload) {
			try {
				let path = `${url}UpdateIsDigitalSignature?LoanApplicationId=${payload.id}&isDigitalSignature=${payload.isDigitalSignature}&ModifiedBy=${rootGetters["auth/getUser"].Id}`;
				const response = await axios.post(path);
				this._vm.$bvToast.toast("Type of signature updated successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating signature", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_coApplicant({ rootGetters }, payload) {
			try {
				let path = `${url}UpdateCoApplicantId?LoanApplicationId=${payload.id}&CoApplicantId=${payload.coApplicantId}&ModifiedBy=${rootGetters["auth/getUser"].Id}`;
				const response = await axios.post(path);
				this._vm.$bvToast.toast("Co Applicant updated successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating Co Applicant", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_guarantor({ rootGetters }, payload) {
			try {
				let path = `${url}UpdateGuarantorId?LoanApplicationId=${payload.id}&GuarantorId=${payload.guarantorId}&ModifiedBy=${rootGetters["auth/getUser"].Id}`;
				const response = await axios.post(path);
				this._vm.$bvToast.toast("Guarantor updated successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating Guarantor", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_incomes({ rootGetters }, payload) {
			try {
				let path = `${url}UpdateIncomes`;
				const response = await axios.post(path, {
					LoanApplicationId: payload.id, 
					body: payload.body,
					ModifiedBy: rootGetters["auth/getUser"].Id
				});
				this._vm.$bvToast.toast("Incomes updated successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating Incomes", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async update_expenses({ rootGetters }, payload) {
			try {
				let path = `${url}UpdateExpenses`;
				const response = await axios.post(path, {
					LoanApplicationId: payload.id, 
					body: payload.body,
					ModifiedBy: rootGetters["auth/getUser"].Id
				});
				this._vm.$bvToast.toast("Expenses updated successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating Expenses", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async update_financialObligations({ rootGetters }, payload) {
			try {
				let path = `${url}UpdateFinancialObligations`;
				const response = await axios.post(path, {
					LoanApplicationId: payload.id, 
					body: payload.body,
					ModifiedBy: rootGetters["auth/getUser"].Id
				});
				this._vm.$bvToast.toast("Financial obligations updated successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating financial obligations", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async get_purposes() {
			try {
				const response = await axios.get(`${url}GetPurposes`);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting purposes", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_purpose({ rootGetters }, payload) {
			try {
				const response = await axios.post(`${url}UpdatePurpose`, {
					"loanApplicationId": payload.id,
					"purposeId": payload.purposeId,
					"modifiedBy": rootGetters["auth/getUser"].Id
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating purposes", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_vehicleDetail({ rootGetters }, payload) {
			try {
				const response = await axios.post(`${url}UpdateVehicleApplicationDetails`, {
					"loanApplicationId": payload.id,
					brand: payload.brand,
					model: payload.model,
					year: payload.year,
					vin:payload.vin,
					condition: payload.condition,
					color: payload.color,
					mileage: payload.mileage,
					price: payload.price,
					appraisal: payload.appraisal,
					sellerName: payload.sellerName,
					sellerContact: payload.sellerContact,
					sellerAddress: payload.sellerAddress,
					modifiedBy: rootGetters["auth/getUser"].Id
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating vehicle information " , {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
	},
};
