import axios from "axios";

export default {
	namespaced: true,
	state() {
		return {
			scans: [],
		};
	},
	getters: {
		getScans(state) {
			return state.scans;
		},
	},
	mutations: {
		SET_SCANS(state, scans) {
			state.scans = scans;
		},
	},
	actions: {
		async memberVerification(_, payload) {
			try {
				const response = await axios.post(`customers/Api/Scans/MemberVerificationRequest`, {
					dataBreachCheckParam: {
						emailAddress: "",
					},
					FirstName: payload.firstName,
					LastName: payload.lastName,
					CustomerId: payload.customerId,
					LoanApplicationId: payload.loanApplicationId,
				});

				this._vm.$bvToast.toast("Member verified successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error verifying member", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_scans({ commit }, payload) {
			try {
				const response = await axios.get(`customers/Api/Scans/GetAllVerificationRequest`, {
					params: {
						Page: 1,
						RecordsPerPage: 999,
						CustomerId: payload.customerId,
						FirstName: payload.firstName,
						LastName: payload.lastName,
						Email: "",
					},
				});
				commit("SET_SCANS", response.data.dataResult);
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting scans", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_scanById(_, payload) {
			try {
				const response = await axios.get(`customers/Api/Scans/GetByCustomerId?CustomerId=${payload}`);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting scan", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
	},
};
