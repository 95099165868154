import axios from "axios";
const url = "loans/Api/DigitalSignature/";

export default {
	namespaced: true,
	state() {
		return {
		};
	},
	getters: {},
	mutations: {
	},
	actions: {
		async create_signProcess(_, id) {
			try {
				const response = await axios.post(`${url}CreateDocumentAndSignProcess`,id, {
                    headers: {
                        'accept': 'text/plain',
                        'Content-Type': 'application/json'
                    }
                });
                this._vm.$bvToast.toast("Sign process created successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error creating document sign process", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

        async get_signProcess(_, id) {
			try {
				const response = await axios.get(`${url}GetSignProcessByLoanApplicationId/${id}`);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting sign process", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},



	},
};
