import axios from "axios";
import { jwtDecode } from "jwt-decode";

export default {
	namespaced: true,
	state() {
		return {
			token: [],
			isAuth: false,
			user: null,
		};
	},
	getters: {
		getToken(state) {
			return state.token;
		},
		getIsAuth(state) {
			return state.isAuth;
		},
		getUser(state) {
			return state.user;
		},
		getAuthorizations(state) {
			if (state.user) {
				return state.user.Authorizations;
			}
			return [];
		},
	},
	mutations: {
		SET_TOKEN(state, token) {
			state.token = token;
			state.isAuth = true;
		},
		SET_USER(state, user) {
			state.user = user;
		},
	},
	actions: {
		async login({ commit }, data) {
			try {
				const response = await axios.post("auth/Api/Login/SignIn", {
					username: data.username,
					password: data.password,
				});
				commit("SET_TOKEN", response.data);
				localStorage.setItem("token", response.data);
				const datUser = jwtDecode(response.data);
				const user = JSON.parse(datUser.Session);
				commit("SET_USER", user);
				return true;
			} catch (error) {
				console.log(error);
				this._vm.$bvToast.toast("Incorrect username or password.", {
					title: "Error",
					variant: "danger",
					toaster: "b-toaster-top-center",
					solid: true,
				});
				return false;
			}
		},

		async signInWithSSO({ commit }, data) {
			try {
				const response = await axios.post(
					"auth/Api/Login/SignInWithSSO",
					{
						username: data.username,
						password: data.token,
					},
					{ headers: { Authorization: `Bearer ${data.token}` } }
				);
				commit("SET_TOKEN", response.data);
				localStorage.setItem("token", response.data);
				const datUser = jwtDecode(response.data);
				const user = JSON.parse(datUser.Session);
				commit("SET_USER", user);
				return true;
			} catch (error) {
				console.log(error);
				this._vm.$bvToast.toast("Authentication Failed.", {
					title: "Error",
					variant: "danger",
					toaster: "b-toaster-top-center",
					solid: true,
				});
				return false;
			}
		},

		async createForgotPwdToken(_, id) {
			try {
				await axios.post(`auth/Api/Login/CreateForgotPwdToken?UserId=${id}`);
				this._vm.$bvToast.toast("Email has been sent successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
			} catch (error) {
				console.log(error);
				this._vm.$bvToast.toast("An error occurred while sending the email", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async resetPwd(_, data) {
			try {
				await axios.put(`auth/Api/Login/ResetPassword`, data);
				this._vm.$bvToast.toast("Password updated successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
			} catch (error) {
				this._vm.$bvToast.toast(`An error occurred while updating password: ${error.responsmessageResult}`, {
					title: "Error",
					variant: "danger",
					solid: true,
				});
				throw new Error(error);
			}
		},

		setToken({ commit }) {
			const token = localStorage.getItem("token");
			if (!token) {
				return;
			}
			commit("SET_TOKEN", token);
			const datUser = jwtDecode(token);
			const user = JSON.parse(datUser.Session);
			commit("SET_USER", user);
		},
		logout({ commit }) {
			localStorage.removeItem("token");
			commit("SET_TOKEN", null);
			commit("SET_USER", null);
		},
	},
};
