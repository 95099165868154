import axios from "axios";

const url = "customers/Api/";

export default {
	namespaced: true,
	state() {
		return {
			customers: [],
			customersToBuilders: [],
			totalCustomers: 0,
			customer: {},
		};
	},
	getters: {
		getCustomers(state) {
			return state.customers;
		},
		getCustomersToBuilders(state) {
			return state.customersToBuilders;
		},
		getTotalCustomers(state) {
			return state.totalCustomers;
		},
		getCustomer(state) {
			return state.customer;
		},
		getDocuments(state) {
			return state.documents;
		},
		getTotalDocuments(state) {
			return state.totalDocuments;
		},
		getDocumentTypes(state) {
			return state.documentTypes;
		},
	},
	mutations: {
		SET_CUSTOMERS(state, customers) {
			state.customers = customers;
		},
		SET_TOTAL_CUSTOMERS(state, totalCustomers) {
			state.totalCustomers = totalCustomers;
		},
		SET_CUSTOMER(state, customer) {
			state.customer = customer;
		},
		SET_DOCUMENTS(state, documents) {
			state.documents = documents;
		},
		SET_TOTAL_DOCUMENTS(state, totalDocuments) {
			state.totalDocuments = totalDocuments;
		},
		SET_DOCUMENT_TYPES(state, documentTypes) {
			state.documentTypes = documentTypes;
		},
	},
	actions: {
		async get_allCustomers({ commit }, payload) {
			console.log(payload.sortProperty)
			try {
				let path = `${url}Profiles/GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}&SortDirection=${payload.sortDirection ?? ''}&SortProperty=${payload.sortProperty ?? ''}`;
				if (payload.status != null) {
					path = path + `&Status=${payload.status}`;
				}
				path = path + `&SearchParam=${payload.query}`;
				const response = await axios.get(path);
				commit("SET_CUSTOMERS", response.data.dataResult);
				commit("SET_TOTAL_CUSTOMERS", response.data.dataRecords);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting profiles", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_allBusiness(_, payload) {
			try {
				let path = `${url}Profiles/GetAll-Business?Page=${payload.skip}&RecordsPerPage=${payload.take}`;
				path = path + `&SearchParam=${payload.query}`;
				const response = await axios.get(path);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting profiles", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_allToBuilders(_, payload) {
			try {
				const response = await axios.get(`${url}Profiles/GetAllToBuilders?Page=${payload.skip}&RecordsPerPage=${payload.take}`);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting profiles", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_customer({ commit }, id) {
			try {
				const response = await axios.get(`${url}Profiles/GetById/${id}`);
				commit("SET_CUSTOMER", response.data.dataResult);
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting profile", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_customerDetail(_, id) {
			try {
				const response = await axios.get(`${url}Profiles/GetById/${id}`);
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting profile", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async update_customer({ rootGetters }, data) {
			try {
				const response = await axios.put(`${url}Profiles/Update`, {
					//ReasonTypeId, FirstName, MiddleName, LastName, TitleId, CityId, Address1, Address2, Address3, Phone1, Phone2, Email1, Email1IsVerified, Email2, Email2IsVerified, CreatedBy
					Id: data.id,
					ReasonTypeId: data.reasonTypeId,
					FirstName: data.firstName,
					MiddleName: data.middleName,
					LastName: data.lastName,
					TitleId: data.titleId,
					CityId: data.cityId,
					Address1: data.address1,
					Address2: data.address2,
					Address3: data.address3,
					Phone1: data.phone1,
					Phone2: data.phone2,
					Email1: data.email1,
					Email1IsVerified: data.email1IsVerified,
					Email2: data.email2,
					Email2IsVerified: data.email2IsVerified,
					ModifiedBy: rootGetters["auth/getUser"].Id,
					IsActive: true,
					MigrationOldId: 1,
				});
				this._vm.$bvToast.toast("Profile updated successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating profile", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async send_emailVerification(_, payload) {
			try {
				const response = await axios.post(`${url}Profiles/SendEmailVerification`, {
					id: payload.id,
					firstName: payload.firstName,
					lastName: payload.lastName,
					email: payload.email,
					emailNumber: payload.emailNumber,
				});
				this._vm.$bvToast.toast("Successfully verification.", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error sending verification", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async update_statusEmailVerification(_, payload) {
			try {
				const response = await axios.put(`${url}Profiles/UpdateStatusEmailVerification`, {
					id: payload.id,
					firstName: payload.firstName,
					lastName: payload.lastName,
					email: payload.email,
					emailNumber: payload.emailNumber,
				});
				this._vm.$bvToast.toast("Successfully status updated.", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return {
					data: response,
					success: true,
				};
			} catch (error) {
				this._vm.$bvToast.toast("Error updating status", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
				return {
					success: false,
				};
			}
		},
		async update_pepInformation({ rootGetters }, payload) {
			try {
				const response = await axios.put(`${url}Profiles/UpdatePEPInformation`, {
					...payload,
					modifiedBy: rootGetters["auth/getUser"].Id,
				});
				this._vm.$bvToast.toast("Successfully PEP information updated.", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return {
					data: response,
					success: true,
				};
			} catch (error) {
				this._vm.$bvToast.toast("Error updating PEP information", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
				return {
					success: false,
				};
			}
		},
	},
};
