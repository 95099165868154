import axios from "axios";
const url = "loans/Api/Rules/"

export default {
    namespaced: true,
    state() {
        return {
            rules: [],
            totalRules: 0
        }
    },
    getters: {
        getRules(state) { return state.rules },
        getTotalRules(state) { return state.totalRules }
    },
    mutations: {
        SET_RULES(state, rules) {
            state.rules = rules;
        },
        SET_TOTAL_RULES(state, totalRules) {
            state.totalRules = totalRules;
        }
    },
    actions: {
        async get_allRules({ commit }, payload) {
            try {
                const response = await axios.get(`${url}GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}`);
                commit('SET_RULES', response.data.dataResult);
                commit('SET_TOTAL_RULES', response.data.dataRecords);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting rules', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async get_rules(_,id) {
            try {
                const response = await axios.get(`${url}GetById/${id}`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error get rules by ID', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async create_rules({rootGetters}, payload) {
            try {
                const response = await axios.post(`${url}Add`, {
                    //MinAmount, MaxAmount, Percentage, MaximumNumberOfMonths, LoanTypeId, CreatedBy, IsActive
                    MinAmount: payload.minAmount,
                    MaxAmount: payload.maxAmount,
                    Percentage: payload.percentage,
                    MaximumNumberOfMonths: payload.maximumNumberOfMonths,
                    LoanTypeId: payload.loanTypeId,
                    CreatedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating rules', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async update_rules({rootGetters}, rules) {
 
            try {
                const response = await axios.put(`${url}Update`, {
                    //Id, MinAmount, MaxAmount, Percentage, MaximumNumberOfMonths, LoanTypeId, ModifiedBy, IsActive
                    Id: rules.id,
                    MinAmount: rules.minAmount,
                    MaxAmount: rules.maxAmount,
                    Percentage: rules.percentage,
                    MaximumNumberOfMonths: rules.maximumNumberOfMonths,
                    LoanTypeId: rules.loanTypeId,
                    ModifiedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                
                return response.data;     
            } catch (error) {
                this._vm.$bvToast.toast('Error update rules', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        }
    }
}