import axios from "axios";

const axiosPlugin = {
	install(Vue) {
		axios.defaults.baseURL = process.env.VUE_APP_API_URL;

		axios.interceptors.request.use(
			(config) => {
				const token = localStorage.getItem("token");
				if (token) {
					config.headers["Authorization"] = `Bearer ${token}`;
				}
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		Vue.prototype.$axios = axios;
	},
};

export default axiosPlugin;
